import { AuthService } from './../services/security/auth.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class MyInterceptor implements HttpInterceptor {

    constructor(private toastrService: ToastrService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('ctdenarendadmintoken');
        req = req.clone({
            setHeaders: {
                Authorisation: `${token}`,
            }
        });
        return next.handle(req).pipe(
            tap(
                event => {
                    // logging the http response to browser's console in case of a success
                    if (event instanceof HttpResponse) {
                        if (event.body === 'tokeninvalid' || event.body === 'unauthorized') {
                            this.toastrService.error('You are not authorized to access this resource' + event.body);
                            this.router.navigateByUrl('/admin');
                        }
                    }
                },
                error => {
                    // logging the http response to browser's console in case of a failuer
                    if (event instanceof HttpResponse) {
                    }
                }
            )
        );
    }
}
