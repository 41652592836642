import { RideService } from './../services/ride.service';
import { Component, OnInit } from '@angular/core';
import { RankingService } from '../services/ranking.service';
import { Rank } from '../model/rank';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { TransitionCheckState } from '@angular/material/checkbox';

@Component({
  selector: 'app-rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.scss']
})
export class RankingsComponent implements OnInit {

  ranking: Rank[];
  dates: string[];

  currentYear = new Date().getFullYear().toString();

  constructor(private rankingService: RankingService, private rideService: RideService) { }

  ngOnInit() {
    this.rideService.getDates().subscribe(x => {
      this.dates = x;
      this.dates.splice(this.dates.indexOf('Toekomst'), 1);
    });
    this.getRanking(this.currentYear);
  }

  getRanking(year: string) {
    this.rankingService.getRanking(year).subscribe(x => { this.ranking = x; });
  }

  getMemberImageLink(rank: Rank) {
    if (rank.foto === null) {
      return 'images/leden/imageTijdelijk.jpg';
    }
    return rank.foto.substring(3, rank.foto.length);
  }

  getRittenBenamig(aantalRitten: number) {
    if (aantalRitten > 1) {
      return 'ritten';
    }
    return 'rit';
  }

}
