import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Rank } from '../model/rank';
import { ApiUrl } from './api-url';

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  constructor(private httpClient: HttpClient) { }

  getRanking(year: string) {
    return this.httpClient.get<Rank[]>(ApiUrl.ApiUrl + 'getRanking.php?jaar="' + year + '"');

  }
}
