import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public showMap = false;

  constructor(private router: Router) { }

  ngOnInit() {
    if (this.router.url === '/contact') {
      this.showMap = true;
    }
  }

  getYear() {
    let now = new Date();
    return now.getFullYear();
  }
}
