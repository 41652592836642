import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  hamburgerOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  clickHamburger(){
    this.hamburgerOpen = !this.hamburgerOpen;
  }

}
