import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ride } from '../model/ride';
import { ApiUrl } from './api-url';
import { RideDetail } from '../model/ride-detail';

@Injectable({
  providedIn: 'root'
})
export class RideService {

  constructor(private httpClient: HttpClient) { }

  getRides(jaar: string) {
    return this.httpClient.get<Ride[]>(ApiUrl.ApiUrl + 'getRides.php?jaar="' + jaar + '"');
  }

  getDates() {
    return this.httpClient.get<string[]>(ApiUrl.ApiUrl + 'getRideDates.php');
  }

  getRideDetail(calendarId: number) {
    return this.httpClient.get<RideDetail[]>(ApiUrl.ApiUrl + 'getRideDetail.php?calendarId="' + calendarId + '"');
  }

  getRideByCalenderId(calendarId: number) {
    return this.httpClient.get<Ride[]>(ApiUrl.ApiUrl + 'getRideByCalenderId.php?calendarId="' + calendarId + '"');
  }
}
