import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MembersComponent } from './core/members/members.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MemberComponent } from './core/member/member.component';
import { NavComponent } from './core/nav/nav.component';
import { MemberDetailComponent } from './core/member-detail/member-detail.component';
import { HomeComponent } from './core/home/home.component';
import { RidesComponent } from './core/rides/rides.component';
import { RankingsComponent } from './core/rankings/rankings.component';
import { ContactComponent } from './core/contact/contact.component';
import { RideComponent } from './core/ride/ride.component';
import { FooterComponent } from './core/footer/footer.component';
import { MyInterceptor } from './admin/interceptor/my-interceptor';
import { RideDetailComponent } from './core/ride-detail/ride-detail.component';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgImageSliderModule } from 'ng-image-slider';

@NgModule({
  declarations: [
    AppComponent,
    MembersComponent,
    MemberComponent,
    NavComponent,
    MemberDetailComponent,
    HomeComponent,
    RidesComponent,
    RankingsComponent,
    ContactComponent,
    RideComponent,
    FooterComponent,
    RideDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LocationStrategy, useClass: HashLocationStrategy }

  ],
  exports: [
    MatIconModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
