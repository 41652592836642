import { Member } from './../model/member';
import { MembersService } from './../services/members.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
  members: Member[];

  constructor(private memberService: MembersService) { }

  ngOnInit() {
    this.memberService.getMembers().subscribe(memb => {
      this.members = memb;
    });
  }

}
