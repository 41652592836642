import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imageObject: Array<object> = [
    // {
    //   image: '../../../assets/sponsors/aboBuildingProjects.png',
    //   thumbImage: '../../../assets/sponsors/aboBuildingProjects.png',
    //   //alt: 'alt of image',
    //   //title: 'title of image'
    // }, 
    {
      image: '../../../assets/sponsors/bakkerijMoeremans.png', // Support base64 image
      thumbImage: '../../../assets/sponsors/bakkerijMoeremans.png', // Support base64 image
    },
    {
      image: '../../../assets/sponsors/tfabrik.png', // Support base64 image
      thumbImage: '../../../assets/sponsors/tfabrik.png', // Support base64 image
    },
    {
      image: '../../../assets/sponsors/groepKerremans.png', // Support base64 image
      thumbImage: '../../../assets/sponsors/groepKerremans.png', // Support base64 image
    },
    {
      image: '../../../assets/sponsors/keurslagerKarelHerlinde.png', // Support base64 image
      thumbImage: '../../../assets/sponsors/keurslagerKarelHerlinde.png', // Support base64 image
    },
    {
      image: '../../../assets/sponsors/rouwcentrumVanHumbeeck.png', // Support base64 image
      thumbImage: '../../../assets/sponsors/rouwcentrumVanHumbeeck.png', // Support base64 image
    },
    {
      image: '../../../assets/sponsors/jacoFabro.png', // Support base64 image
      thumbImage: '../../../assets/sponsors/jacoFabro.png', // Support base64 image
    },
    {
      image: '../../../assets/sponsors/smokt.jpg', // Support base64 image
      thumbImage: '../../../assets/sponsors/smokt.jpg', // Support base64 image
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  clickImage(event) {
    switch (event) {
      case 0: window.open('https://www.bakkerijmoeremans.be/', '_blank'); break;
      case 1: window.open('https://tfabrik.be/', '_blank'); break;
      case 2: window.open('http://www.groepkerremans.be/', '_blank'); break;
      case 3: window.open('https://www.keurslagerkarelenherlinde.be/', '_blank'); break;
      case 4: window.open('https://vanhumbeecknv.be/', '_blank'); break;
      case 5: window.open('https://www.jacofabro.be/?fbclid=IwAR3WDjB7oj_Dd17wXSD0WL4fojwAjBXHazkYtS8z3BpBQRkmFAdsCQ_7aG0', '_blank'); break;
      case 6: window.open('https://www.facebook.com/SMOKTWillebroek/', '_blank'); break;
    }
  }

}
