import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrl } from './api-url';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient: HttpClient) { }

  sendEmail(subject: string, email: string, message: string ) {
    const Subject = subject;
    const Message = message;
    const Email = email;

    return this.httpClient.post(ApiUrl.ApiUrl + 'contact.php', { Subject, Message, Email });  }
}
