import { MemberTotals } from './../model/member-totals';
import { MemberDetail } from './../model/member-detail';
import { MembersService } from './../services/members.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ride } from '../model/ride';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit {

  private memberId: number;
  public memberTotals: MemberTotals;
  public rides: Ride[];

  constructor(private route: ActivatedRoute, private memberService: MembersService) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.memberId = +params.id;
        this.getMemberRides();
        this.getTotals();
      });
  }

  getMemberRides() {
    this.memberService.getMemberRides(this.memberId).subscribe(x => this.rides = x);
  }

  getTotals() {
    this.memberService.getMemberRidesTotals(this.memberId).subscribe(x => {this.memberTotals = x[0]; console.log(x); });
  }
}
