import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from './../services/contact.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  disableForm = false;

  constructor(private contactService: ContactService, private formBuilder: FormBuilder, private toastrService: ToastrService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.disableForm = false;
    this.contactForm = this.formBuilder.group({
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
  }

  sendEmail() {
    if (this.contactForm.valid) {
      this.disableForm = true;
      this.contactService.sendEmail(this.contactForm.value.subject, this.contactForm.value.email, this.contactForm.value.message).subscribe(x => {
        if (x === 'succes') {
          this.toastrService.success('Email werd verstuurd, wij antwoorden zo snel mogelijk !');
          this.buildForm();
        } else {
          this.toastrService.error('Oeps er is iets fout gegaan bij het versturen, probeer opnieuw aub !');
          this.disableForm = false;
        }
      });
    }
  }

}
