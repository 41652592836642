import { MemberDetail } from './../model/member-detail';
import { Component, OnInit, Input } from '@angular/core';
import { Member } from '../model/member';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {

  @Input() member: Member;
  memberDetail: MemberDetail[];
  constructor(private router: Router) { }

  ngOnInit() {
  }

  getDetail(ledenId: number) {
    this.router.navigateByUrl('/members/' + ledenId);
  }

  getMemberImageLink() {
    if (this.member.foto === null) {
      return 'images/leden/imageTijdelijk.jpg';
    }
    return this.member.foto.substring(3, this.member.foto.length);
  }
}
