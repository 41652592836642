export class Ride {
    datum: Date;
    kilometers: number;
    ritNaam: string;
    verantwoordelijke: string;
    lastig: string;
    gpsLink: string;
    kalenderId: number;
    ritVertrektijd: string;
}
