import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RideService } from '../services/ride.service';
import { RideDetail } from '../model/ride-detail';
import { Ride } from '../model/ride';

@Component({
  selector: 'app-ride-detail',
  templateUrl: './ride-detail.component.html',
  styleUrls: ['./ride-detail.component.scss']
})
export class RideDetailComponent implements OnInit {

  private calendarId: number;
  rideDetails: RideDetail[];
  currentRide: Ride;

  constructor(private route: ActivatedRoute, private rideService: RideService) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.calendarId = +params.id;
        this.getRideDetail(this.calendarId);
        this.getRide(this.calendarId);
      });
  }

  getRide(calendarId: number) {
    this.rideService.getRideByCalenderId(calendarId).subscribe(x => { this.currentRide = x[0]; });
  }

  getRideDetail(calendarId: number) {
    this.rideService.getRideDetail(calendarId).subscribe(x => { this.rideDetails = x; });
  }

}
