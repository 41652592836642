import { MemberTotals } from './../model/member-totals';
import { ApiUrl } from './api-url';
import { MemberDetail } from './../model/member-detail';
import { Member } from './../model/member';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ride } from '../model/ride';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  constructor(private httpClient: HttpClient) { }

  getMembers() {
    return this.httpClient.get<Member[]>(ApiUrl.ApiUrl + 'getMembers.php');
  }

  getMemberRides(ledenId: number) {
    return this.httpClient.get<Ride[]>(ApiUrl.ApiUrl + 'getMemberRides.php?ledenId="' + ledenId + '"');
  }

  getMemberRidesTotals(ledenId: number) {
    return this.httpClient.get<MemberTotals[]>(ApiUrl.ApiUrl + 'getMemberRidesTotals.php?ledenId="' + ledenId + '"');
  }
}
