import { Component, OnInit, Input } from '@angular/core';
import { Ride } from '../model/ride';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ride',
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.scss']
})
export class RideComponent implements OnInit {

  @Input() ride: Ride;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  getDetail(calendarId: number) {
    this.router.navigateByUrl('/rides/' + calendarId);
  }
}
