import { Component, OnInit } from '@angular/core';
import { RideService } from '../services/ride.service';
import { Ride } from '../model/ride';

@Component({
  selector: 'app-rides',
  templateUrl: './rides.component.html',
  styleUrls: ['./rides.component.scss']
})
export class RidesComponent implements OnInit {

  rides: Ride[];
  dates: string[];

  constructor(private rideService: RideService) { }

  ngOnInit() {
    this.rideService.getDates().subscribe(x => {
      this.dates = x;
      this.getRides('Toekomst');
    });
  }

  getRides(year: string) {
    this.rideService.getRides(year).subscribe(x => { this.rides = x; });
  }

}
