import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembersComponent } from './core/members/members.component';
import { MemberDetailComponent } from './core/member-detail/member-detail.component';
import { HomeComponent } from './core/home/home.component';
import { RidesComponent } from './core/rides/rides.component';
import { RankingsComponent } from './core/rankings/rankings.component';
import { ContactComponent } from './core/contact/contact.component';
import { RideDetailComponent } from './core/ride-detail/ride-detail.component';

const routes: Routes = [

  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'members', component: MembersComponent, pathMatch: 'full' },
  { path: 'members/:id', component: MemberDetailComponent, pathMatch: 'full' },
  { path: 'rides', component: RidesComponent, pathMatch: 'full' },
  { path: 'rides/:id', component: RideDetailComponent, pathMatch: 'full' },
  { path: 'rankings', component: RankingsComponent, pathMatch: 'full' },
  { path: 'contact', component: ContactComponent, pathMatch: 'full' },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
